<template>
  <div class="pb-4">
    <b-row>
      <b-col md="12 padding-top-16">
        <p class="text-center">
          <span
            class="aiops_neutral_blue_heading1"
            style="text-transform: uppercase;"
          >
            YOUR BUSINESS PROCESS
          </span>
        </p>
      </b-col>
    </b-row>
    <b-row class="toolbar mb-3 padding-top-16">
      <b-col>
        <span
          id="unsavedChanges"
          class="hidden aiops_accent_red_heading3"
        >
          File contains unsaved changes.
        </span>
      </b-col>
      <p>
        <b-button
          id="undo"
          class="ibop-mineral-green-button mr-1"
        >
          Undo</b-button>
        <b-button
          id="redo"
          class="ibop-mineral-green-button mr-1"
        >
          Redo</b-button>
        <b-button
          id="download"
          class="ibop-mineral-green-button mr-1"
        >
          Download</b-button>
        <b-button
          id="downloadSvg"
          class="ibop-mineral-green-button mr-1"
        >
          Download SVG</b-button>
        <b-button
          id="load"
          class="ibop-mineral-green-button mr-1"
        >
          Load</b-button>
      </p>
    </b-row>
    <b-row id="aiops-teis-bpmn-editor-container" />
  </div>
</template>

<script>
import {
  BTable,
  BTr,
  BTd,
  BTh,
  BTfoot,
  BTbody,
  BThead,
  BButton,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BBadge,
  BJumbotron,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BProgress,
  BProgressBar,
  BSpinner,
  BTableLite,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import '@/assets/scss/aiops_slider/aiops_slider.css'
// import * as DmnEditor from '@kogito-tooling/kie-editors-standalone/dist/dmn'
import * as BpmnEditor from '@kogito-tooling/kie-editors-standalone/dist/bpmn'
import Ripple from 'vue-ripple-directive'
// import useJwt from '@/auth/aiops/useJwt'
import utilsAiops from '@/@core/utils/aiops/utils'
import store from '@/store'
import modelBpmn from './teis.bpmn2'

const {
  loading,
} = utilsAiops()

export default {
  components: {
    BTable,
    BTr,
    BTd,
    BTh,
    BTfoot,
    BTbody,
    BThead,
    BButton,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BBadge,
    BJumbotron,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BProgress,
    BProgressBar,
    BSpinner,
    VueSlider,
    BTableLite,
  },
  directives: {
    Ripple,
  },
  data() {
    return {}
  },
  created() {
    if (!this.$ability.can('aiopsuser')) {
      this.$router.push({ name: 'aiops-home' })
    }
    this.welcomeAssist()
  },
  mounted() {
    const editor = BpmnEditor.open({
      container: document.getElementById('aiops-teis-bpmn-editor-container'),
      initialContent: fetch(modelBpmn).then(content => content.text()),
      readOnly: false,
    })

    document.getElementById('undo').addEventListener('click', () => {
      editor.undo()
    })

    document.getElementById('redo').addEventListener('click', () => {
      editor.redo()
    })

    document.getElementById('download').addEventListener('click', () => {
      editor.getContent().then(content => {
        const elem = window.document.createElement('a')
        elem.href = `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`
        elem.download = 'model.bpmn'
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
        editor.markAsSaved()
      })
    })

    document.getElementById('downloadSvg').addEventListener('click', () => {
      editor.getPreview().then(svgContent => {
        const elem = window.document.createElement('a')
        elem.href = `data:image/svg+xmlcharset=utf-8,${encodeURIComponent(svgContent)}`
        elem.download = 'model.svg'
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
      })
    })

    document.getElementById('load').addEventListener('click', () => {
      editor.setContent('./Users/jdparra/Downloads/Untitled-3.bpmn')
    })

    editor.subscribeToContentChanges(isDirty => {
      if (isDirty) {
        document.getElementById('unsavedChanges').classList.remove('hidden')
      } else {
        document.getElementById('unsavedChanges').classList.add('hidden')
      }
    })
    loading(false)
  },
  methods: {
    errorRequest() {
      store.dispatch('productAiops/errorRequest')
    },
    errorMessage(message = 'Something was wrong') {
      const variant = 'warning'
      this.$bvToast.toast(message, {
        title: 'Classification correction',
        variant,
        solid: true,
      })
    },
    welcomeAssist() {
      store.dispatch('productAiops/showWelcomeAssist', false)
    },
  },
}
</script>

<style lang="scss" scoped>

#aiops-teis-bpmn-editor-container {
  height: calc(100vh - 50px);
}

.toolbar {
  height: 30px;
}

.hidden {
  display: none;
}

html, body{
height: 100%;
}

</style>
